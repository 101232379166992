import { Form, Input, Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { HeaderForm } from './HeaderForm';

const FormSchema = z.object({
  namaPerusahaan: z.string({
    required_error: "Nama perusahaan tidak boleh kosong"
  }).nonempty({
    message: "Nama perusahaan tidak boleh kosong",
  }).max(255, {
    message: "Nama perusahaan harus kurang dari 255 karakter",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  }),
  posisi: z.string({
    required_error: "Posisi tidak boleh kosong"
  }).nonempty({
    message: "Posisi tidak boleh kosong",
  }).max(255, {
    message: "Posisi harus kurang dari 255 karakter",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  }),
  tahunMasuk: z
    .string({
      required_error: "Tahun Masuk tidak boleh kosong"
    })
    .nonempty({
      message: "Tahun Masuk tidak boleh kosong",
    }).refine(value => !/^\s*$/.test(value), {
      message: 'Tidak boleh hanya berisi spasi',
    })  
    .refine(value => /^[0-9]*$/.test(value), {
    message: 'Tahun Masuk harus berupa angka'
  }),
  tahunKeluar: z
    .string({
      required_error: "Tahun Keluar tidak boleh kosong"
    }
  ).nonempty({
    message: "Tahun Keluar tidak boleh kosong",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  })
  .refine(value => /^[0-9]*$/.test(value), {
    message: 'Tahun Keluar harus berupa angka'
  }),
  namaReferensi: z.string({
    required_error: "Nama referensi tidak boleh kosong"
  }).nonempty({
    message: "Nama referensi tidak boleh kosong",
  }).max(255, {
    message: "Nama referensi harus kurang dari 255 karakter",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  }),
  kontakReferensi: z.string({
    required_error: "Kontak referensi tidak boleh kosong"
  }).nonempty({
    message: "Kontak referensi tidak boleh kosong",
  }).max(255, {
    message: "Kontak referensi harus kurang dari 255 karakter",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  }).refine(value => /^[0-9]*$/.test(value), {
    message: 'Kontak referensi harus berupa angka'
  }),
  childCompany: z
    .array(
      z.object({
        namaPerusahaan: z
          .string()
          .nonempty({
            message: "Nama perusahaan tidak boleh kosong",
          })
          .max(255, {
            message: "Nama perusahaan harus kurang dari 255 karakter",
          }).refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }),
        posisi: z
          .string()
          .nonempty({
            message: "Posisi tidak boleh kosong",
          })
          .max(255, {
            message: "Posisi harus kurang dari 255 karakter",
          }).refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }),
        tahunMasuk: z
          .string()
          .refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }),
        tahunKeluar: z
          .string()
          .refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }),
        namaReferensi: z
          .string()
          .nonempty({
            message: "Nama referensi tidak boleh kosong",
          })
          .max(255, {
            message: "Nama referensi harus kurang dari 255 karakter",
          }).refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }),
        kontakReferensi: z
          .string()
          .nonempty({
            message: "Kontak referensi tidak boleh kosong",
          })
          .max(255, {
            message: "Kontak referensi harus kurang dari 255 karakter",
          }).refine(value => !/^\s*$/.test(value), {
            message: 'Tidak boleh hanya berisi spasi',
          }).refine(value => /^[0-9]*$/.test(value), {
            message: 'Kontak referensi harus berupa angka'
          }),
      })
    )
    .min(0),
});

export type workHistoryFormDataType = z.infer<typeof FormSchema>;

const WorkHistoryForm: React.FC<{
  onSubmit: (data: workHistoryFormDataType) => void;
  workHistoryForm?: workHistoryFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, workHistoryForm, onNextPage, onPrevPage }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<workHistoryFormDataType>({
    defaultValues: {
      tahunMasuk: undefined,
      tahunKeluar: undefined,
    },
    resolver: zodResolver(FormSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "childCompany",
  });

  useEffect(() => {
    if (workHistoryForm) {
      setValue("namaPerusahaan", workHistoryForm.namaPerusahaan);
      setValue("posisi", workHistoryForm.posisi);
      setValue("tahunMasuk", workHistoryForm.tahunMasuk);
      setValue("tahunKeluar", workHistoryForm.tahunKeluar);
      setValue("namaReferensi", workHistoryForm.namaReferensi);
      setValue("kontakReferensi", workHistoryForm.kontakReferensi);
      setValue("childCompany", workHistoryForm.childCompany)
    }
  }, [workHistoryForm, setValue]);

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  function onHandleFormSubmit(data: workHistoryFormDataType) {
    onSubmit(data);
    onNextPage();
  }

  const currentYear = new Date().getFullYear();
  const lastFiveYears = Array.from({ length: 50 }, (_, index) => ({
    value: `${currentYear - index}`,
    label: `${currentYear - index}`,
  }));

  return (
    <>
      <HeaderForm steps={2} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">
          D. PENGALAMAN BEKERJA (Maksimal 5 Perusahaan Terakhir)
        </div>
        <div className="text-gray-500 text-center italic">
          (isi (-) jika tidak memiliki pengalaman bekerja)
        </div>
        <div className="mr-2 mb-3 font-bold">
          Perusahaan Ke-1
        </div>
        <Form.Item
          name="companyName"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Perusahaan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaPerusahaan ? "error" : ""}
          help={errors.namaPerusahaan ? errors.namaPerusahaan.message : ""}
        >
          <Controller
            name="namaPerusahaan"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama perusahaan" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="position"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Posisi</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.posisi ? "error" : ""}
          help={errors.posisi ? errors.posisi.message : ""}
        >
          <Controller
            name="posisi"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan posisi" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tahunMasuk"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tahun Masuk</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tahunMasuk ? "error" : ""}
          help={errors.tahunMasuk ? errors.tahunMasuk.message : ""}
        >
          <Controller
            name="tahunMasuk"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                placeholder="Masukkan tahun masuk"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={lastFiveYears}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tahunKeluar"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tahun Keluar</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tahunKeluar ? "error" : ""}
          help={errors.tahunKeluar ? errors.tahunKeluar.message : ""}
        >
          <Controller
            name="tahunKeluar"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                placeholder="Masukkan tahun keluar"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={lastFiveYears}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="referencerName"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Referensi (Berikan alasan jika tidak ada)</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaReferensi ? "error" : ""}
          help={errors.namaReferensi ? errors.namaReferensi.message : ""}
        >
          <Controller
            name="namaReferensi"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama referensi" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="referencerContact"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Kontak Referensi </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.kontakReferensi ? "error" : ""}
          help={errors.kontakReferensi ? errors.kontakReferensi.message : ""}
        >
          <Controller
            name="kontakReferensi"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nomor kontak referensi"
              />
            )}
          />
        </Form.Item>

        <Form.Item>
          {fields.map((item, index) => (
            <div key={item.id} className='mb-5'>
              <label className="mr-2 font-bold mb-2">
                Perusahaan Ke-{index + 2}
              </label>

              <Form.Item
                name="namaPerusahaan"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 mt-3 font-medium">Nama Perusahaan</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.namaPerusahaan ? "error" : ""}
                help={errors.childCompany?.[index]?.namaPerusahaan ? errors.childCompany[index]?.namaPerusahaan?.message : ""}
              >
                <Controller
                  name={`childCompany.${index}.namaPerusahaan`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan nama perusahaan" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="posisi"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Posisi</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.posisi ? "error" : ""}
                help={errors.childCompany?.[index]?.posisi ? errors.childCompany[index]?.posisi?.message : ""}
              >
                <Controller
                  name={`childCompany.${index}.posisi`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan posisi" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="startYear"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tahun Masuk</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.tahunMasuk ? "error" : ""}
                help={errors.childCompany?.[index]?.tahunMasuk ? errors.childCompany[index]?.tahunMasuk?.message : ""}
              >
                <Controller
                  name={`childCompany.${index}.tahunMasuk`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      showSearch
                      placeholder="Masukkan tahun masuk"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={lastFiveYears}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="endYear"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tahun Keluar</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.tahunKeluar ? "error" : ""}
                help={errors.childCompany?.[index]?.tahunKeluar ? errors.childCompany[index]?.tahunKeluar?.message : ""}
              >
                <Controller
                  name={`childCompany.${index}.tahunKeluar`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      showSearch
                      placeholder="Masukkan tahun keluar"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={lastFiveYears}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="namaReferensi"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Nama Referensi (Berikan alasan jika tidak ada)</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.namaReferensi ? "error" : ""}
                help={errors.childCompany?.[index]?.namaReferensi ? errors.childCompany[index]?.namaReferensi?.message : ""}
              >
                <div className="flex flex-col">
                  <Controller
                    name={`childCompany.${index}.namaReferensi`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder="Masukkan nama referensi" />
                    )}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="kontakReferensi"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Kontak Referensi </label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.childCompany?.[index]?.kontakReferensi ? "error" : ""}
                help={errors.childCompany?.[index]?.kontakReferensi ? errors.childCompany[index]?.kontakReferensi?.message : ""}
              >
                <div className="flex flex-col">
                  <Controller
                    name={`childCompany.${index}.kontakReferensi`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder="Masukkan kontak referensi" />
                    )}
                  />
                </div>
              </Form.Item>
            </div>
          ))}

          <div className='flex'>
            {fields.length < 4 && (
              <button
                className={`text-white bg-[#948A54] border rounded-md mt-2 mr-3 ${fields.length === 4 ? "disabled" : "hover:bg-blue-500"}`}
                type="button"
                disabled={fields.length === 4}
                onClick={() =>
                  append({
                    namaPerusahaan: "",
                    posisi: "",
                    tahunMasuk: "0",
                    tahunKeluar: "0",
                    namaReferensi: "",
                    kontakReferensi: "",
                  })
                }
              >
                <div className='px-4 m-1'>Tambah Perusahaan</div>
              </button>
            )}
            {fields.length >= 1 && (
              <button
                type="button"
                disabled={fields.length === 0}
                className="text-white bg-[#EF3E55] border rounded-md mt-2"
                onClick={() => {
                  if (fields.length >= 1) {
                    remove(fields.length - 1);
                  }
                }}
              >
                <div className='px-4 m-1'>Hapus Perusahaan</div>
              </button>
            )}

          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button className="bg-[#948A54]" type="primary" htmlType="submit">
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default WorkHistoryForm;
